<!-- @format -->
<template>
    <div v-if="showEnvironment" :class="{ 'na-debug-environment': addContainerWrapper }">
        <p class="is-size-6 na-debug-environment-test has-text-centered">
            This is not the live environment. Do NOT enter live data here if you expect it to be visible to the public.
        </p>
    </div>
</template>

<script>
export default {
    props: {
        addContainerWrapper: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        showEnvironment() {
            return window.naVariables.naEnvironment !== 'production' && window.naVariables.naEnvironment !== 'prod';
        },
        environment() {
            return window.naVariables.naEnvironment;
        },
        environmentNaBranch() {
            return window.NA_BRANCH;
        }
    }
};
</script>
