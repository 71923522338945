<!-- @format -->
<template>
    <nav class="is-flex is-justify-content-center na-header">
        <div class="container" :class="{ 'is-fluid': !clientIsMobile }">
            <div class="is-flex is-flex-grow-1 is-justify-content-space-between">
                <nav-left v-if="isShowNavMenus" />
                <nav-center :class="{ 'is-centered': !isShowNavMenus }" />
                <nav-right v-if="isShowNavMenus" />
            </div>
        </div>
    </nav>
</template>

<script>
import NavLeft from './Left.vue';
import NavCenter from './Center.vue';
import NavRight from './Right.vue';

export default {
    name: 'NavHead',

    components: {
        NavLeft,
        NavCenter,
        NavRight
    },

    computed: {
        isKwSpecial() {
            return this.$route.path.startsWith('/special');
        },

        isShowNavMenus() {
            //return true;
            return !this.isKwSpecial;
        }
    }
};
</script>
<style scoped>
.is-centered {
  margin: 0 auto;
  text-align: center;
}
</style>